<template>

  <div>
    <b-card
        class="mb-0 bg-primary text-white"
    >
      <h4 class="text-white">
        {{ this.title }}
      </h4>
    </b-card>
    <b-card
        v-for="(value, index) in posts"
        :key="index"
        class="mb-0 mt-1 "
    >
      <div class="d-flex justify-content-between align-items-center mb-1">
        <b-avatar
            size="42"
            :src="value.user.photo ? `https://edu.startupchoikhona.tj/backend/${value.user.photo}` : require('@/assets/images/user/noPhoto.jpeg')"
            class="badge-minimal"
            :badge-variant="'success'"
            variant="transparent"
        />
        <div class="chat-info flex-grow-1">
          <h5 class="mb-0 ml-1">
            {{ value.user.name }}
          </h5>
        </div>
        <div
            class="chat-meta text-nowrap"
        >
          <small class="float-right mb-25 chat-time">{{ formatDateToMonthShort(value.created_at) }}</small>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center mb-1">

        <div><p v-html="value.body"></p></div>
        <div class="d-flex align-items-center">
          <span @click="editPost(index)" v-if="userDataStorage.roles[0] === 'admin' || userDataStorage.email === value.user.email">
            <feather-icon icon="EditIcon" class="text-primary mr-1 font-medium-5"/>
          </span>
          <span @click="deletePost(index)" v-if="userDataStorage.roles[0] === 'admin' || userDataStorage.email === value.user.email">
            <feather-icon icon="Trash2Icon" class="text-danger mr-1 font-medium-5"/>
          </span>
        </div>
      </div>
    </b-card>
    <b-card
        class="mb-0 mt-1"
    >

      <quill-editor
          id="description"
          v-model="question"
          :options="editorOption"
      />
      <b-button
          variant="info"
          class="mt-1 float-right"
          @click="cancelEditPost"
          v-if="postID > 0"
      >
        Отменить
      </b-button>
      <b-button
          variant="primary"
          class="mt-1 mr-2 float-right"
          @click="sendrPost"
      >
        Отправить
      </b-button>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BAvatar, BBadge
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import ImageResize from 'quill-image-resize-vue'
import * as Quill from 'quill'
import { formatDateToMonthShort } from '@core/utils/filter'

Quill.register('modules/imageResize', ImageResize)

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    quillEditor,
    ImageResize,
    BAvatar,
    BBadge,
  },
  data() {
    return {
      userDataStorage: JSON.parse(localStorage.getItem('userData')),
      id: 0,
      postID: 0,
      title: '',
      question: '',
      posts: [],
      editorOption: {
        theme: 'snow',
        bounds: '#scrolling-container',
        scrollingContainer: '#scrolling-container',
        placeholder: 'Описание',
        modules: {
          imageResize: {},
          toolbar: {
            container: [
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              ['bold', 'italic', 'underline', 'strike'],
              [{ color: [] }, { background: [] }],
              [{ script: 'super' }, { script: 'sub' }],
              [{ header: '1' }, { header: '2' }, 'blockquote', 'code-block'],
              [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
              [{ direction: 'rtl' }, { align: [] }],
              ['link', 'image', 'video', 'formula'],
              ['clean'],
            ],
          },
        },
      },
    }
  },
  created() {
    this.id = this.$router.currentRoute.params.id
    this.chatterDiscussion()
    this.chatterPosts()
  },
  setup() {
    return { formatDateToMonthShort }
  },
  methods: {
    chatterDiscussion() {
      this.$http
          .post(`https://edu.startupchoikhona.tj/backend/api/student/chatter-discussion/${this.id}/view`, {})
          .then(response => {
            const {
              data,
            } = response.data
            this.title = data.title
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Ошибка при получение менторы',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
    },
    chatterPosts() {
      this.$http
          .post('https://edu.startupchoikhona.tj/backend/api/student/chatter-post/index', {
            chatter_discussion_id: this.id,
          })
          .then(response => {
            const {
              data,
            } = response.data
            this.posts = data.items
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Ошибка при получение менторы',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
    },
    sendrPost() {
      if (this.postID > 0) {
        this.$http
            .post(`https://edu.startupchoikhona.tj/backend/api/student/chatter-post/update/${this.postID}`, {
              chatter_discussion_id: this.id,
              body: this.question,
            })
            .then(() => {
              this.chatterPosts()
              this.postID = 0
              this.question = ''
            })
            .catch(() => {
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Ошибка при получение менторы',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
      } else {
        this.$http
            .post('https://edu.startupchoikhona.tj/backend/api/student/chatter-post/create', {
              chatter_discussion_id: this.id,
              body: this.question,
            })
            .then(() => {
              this.chatterPosts()
              this.question = ''
            })
            .catch(() => {
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Ошибка при получение менторы',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
      }

    },
    editPost(index) {
      this.postID = this.posts[index].id
      this.question = this.posts[index].body
    },
    deletePost(index) {
      this.$http
          .post(`https://edu.startupchoikhona.tj/backend/api/student/chatter-post/delete/${this.posts[index].id}`, {})
          .then(() => {
            this.chatterPosts()
          })
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Ошибка при получение менторы',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
    },
    cancelEditPost() {
      this.postID = 0
      this.question = ''
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
